<template>
  <div class="addCustom">
    <div class="top">
      <div class="title">
        <EditPen style="width: 14px;"></EditPen>
        <span>新增客户</span>
      </div>
      <div>
        <el-button type="primary" @click="clearAll">清空</el-button>
        <el-button type="primary" @click="confirmAdd">新增</el-button>
      </div>
    </div>
    <div class="type">
      <div>新增客户类型</div>
      <el-radio-group v-model="enterprise_type">
        <el-radio :label="1" size="large">商户</el-radio>
        <el-radio :label="2" size="large">服务商</el-radio>
      </el-radio-group>
    </div>
    <div v-show="enterprise_type == 1" class="content">
      <div class="row">
        <div class="label">客户名称</div>
        <el-input v-model="params1.ent_name" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label1">统一社会信用代码</div>
        <el-input v-model="params1.ebc_code" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label">海关登记代码</div>
        <el-input v-model="params1.enterprise_record_no" style="width: 230px;"></el-input>
      </div>
      <div class="row">
        <div class="label">联系人名称</div>
        <el-input v-model="params1.contacts" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label1">联系人电话</div>
        <el-input v-model="params1.contacts_phone" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label">邮箱</div>
        <el-input v-model="params1.email" style="width: 230px;"></el-input>
      </div>
      <div class="row">
        <div class="label">营业执照</div>
        <el-upload class="avatar-uploader" :show-file-list="false" :auto-upload="false"
          @change="(file) => uploadUrl(file, 1)">
          <img v-if="params1.license_url" :src="params1.license_url" class="avatar"
            style="width: 178px;height: 178px;" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </div>
    </div>
    <div v-show="enterprise_type == 2" class="content">
      <div class="row">
        <div class="label">客户名称</div>
        <el-input v-model="params2.ent_name" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label1">统一社会信用代码</div>
        <el-input v-model="params2.ebc_code" style="width: 230px;"></el-input>
      </div>
      <div class="row">
        <div class="label">联系人名称</div>
        <el-input v-model="params2.contacts" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label1">联系人电话</div>
        <el-input v-model="params2.contacts_phone" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label">邮箱</div>
        <el-input v-model="params2.email" style="width: 230px;"></el-input>
      </div>
      <div class="row">
        <div class="label">海关登记代码</div>
        <el-input v-model="params2.enterprise_record_no" style="width: 230px;margin-right: 20px;"></el-input>
        <div class="label1">DXP</div>
        <el-input v-model="params2.dxp" style="width: 230px;"></el-input>
      </div>
      <div class="row">
        <div class="label">营业执照</div>
        <el-upload class="avatar-uploader" :show-file-list="false" :auto-upload="false"
          @change="(file) => uploadUrl(file, 2)">
          <img v-if="params2.license_url" :src="params2.license_url" class="avatar"
            style="width: 178px;height: 178px;" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script setup>
import { uploadFile } from '@/api/public/index.js';
import { add_enterprise } from '@/api/bank/customer.js'
import { EditPen, Plus } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';

const enterprise_type = ref(1)
// 商户参数
const params1 = ref({
  ent_name: "",
  ebc_code: "",
  contacts: "",
  contacts_phone: "",
  email: "",
  license_url: "",
  dxp: "",
  enterprise_record_no: ""
})
// 服务商参数
const params2 = ref({
  ent_name: "",
  ebc_code: "",
  contacts: "",
  contacts_phone: "",
  email: "",
  license_url: "",
  dxp: "",
  enterprise_record_no: ""
})
// 上传营业执照
function uploadUrl(file, index) {
  uploadFile(file.raw).then(res => {
    if (res.code == 200) {
      if (index == 1) {
        params1.value.license_url = res.data.url
      } else if (index == 2) {
        params2.value.license_url = res.data.url
      }
    }
  })
}
// 清空
function clearAll() {
  if (enterprise_type.value == 1) {
    params1.value = {
      ent_name: "",
      ebc_code: "",
      contacts: "",
      contacts_phone: "",
      email: "",
      license_url: "",
      dxp: "",
      enterprise_record_no: ""
    }
  } else if (enterprise_type.value == 2) {
    params2.value = {
      ent_name: "",
      ebc_code: "",
      contacts: "",
      contacts_phone: "",
      email: "",
      license_url: "",
      dxp: "",
      enterprise_record_no: ""
    }
  }
}

function confirmAdd() {
  let params;
  if (enterprise_type.value == 1) {
    params = params1.value
    params.enterprise_type = 1
  } else if (enterprise_type.value == 2) {
    params = params2.value
    params.enterprise_type = 2
  }
  add_enterprise(params).then(res => {
    if (res.code == 200) {
      ElMessage.success('新增成功')
      clearAll()
    }
  })
}
</script>

<style scoped>
.addCustom {
  padding: 0 20px;
  width: 90%;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #409eff;
}

.type {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 20px;
}

.label {
  width: 85px;
  text-align: right;
}

.label1 {
  width: 120px;
  text-align: right;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px solid var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>